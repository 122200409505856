/*
.slide-item-count-9 > section {
    height: calc((100vh / 3) + 1px)!important;
}
*/
.client-slide {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.client-slide img {
    transform: scale(.75);
}

.client-slide-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.client-slide-image {
}
@media only screen and (max-width: 1200px) {
    .client-slide img {
        transform: scale(.55);
    }
}
@media only screen and (max-width: 992px) {
    .client-slide img {
        transform: scale(.45);
    }
}
@media only screen and (max-width: 600px) {
    .client-slide img {
        transform: scale(.55);
    }
}
.client-slide-background {
    min-width: 100%;
    min-height: 100%;
    background: center/contain no-repeat;
    z-index: 0;
}
