@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../styles/abstracts/breakpoints.css';

.logo {
    position: absolute;
    top: 15px;
    left: 18px;
    z-index: 1000;
    cursor: pointer;
    width: 66px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo:hover {
    outline: none;
}

@media not all and (pointer: coarse) {
    .logo:hover > svg {
        transform: scale(1.2, 1.2);
    }
}

.logoSvg {
    transition: all 0.25s;
    width: 39px;
    height: 26px;
}

@media --to-tablet-width {
    .logo {
        width: 92px;
        height: 82px;
        top: 40px;
        left: 44px;
    }

    .logoSvg {
        width: 52px;
        height: 35px;
    }
}

@media --to-big-desktop-width {
    .logo {
        top: 64px;
        left: 70px;
    }
}
