@value --white, --turquoise from '../../../styles/abstracts/colors.css';
@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';

.menuLink {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    font-size: 34px;
    line-height: 48px;
    font-weight: 400;
    padding: 0;
    display: block;
}

.textElementWhite {
    position: absolute;
    top: 0;
    color: --white;
}

.textElementBlue {
    color: --turquoise;
    opacity: 0;
}

@media --to-tablet-width {
    .menuLink {
        font-size: 40px;
        line-height: 60px;
        font-weight: 500;
    }
}

@media --to-big-desktop-width {
    .menuLink {
        font-size: 48px;
        line-height: 70px;
    }
}
