@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../abstracts/breakpoints.css';

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-scrollbar {
    border-radius: 0;
    position: relative;
    -ms-touch-action: none;
    background: rgba(151, 151, 151, 1);
}

.swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    width: 10px;
    height: 100%;
}
.swiper-scrollbar .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: #02cbc9;
    border-radius: 0;
    left: 0;
    top: 0;
}
.swiper-scrollbar {
    border-radius: 0;
    position: relative;
    -ms-touch-action: none;
    background: rgba(151, 151, 151, 0);
}
@media only screen and (max-width: 600px) {
    .swiper-container-vertical > .swiper-scrollbar {
        width: 6px;
    }
}

/*PROJECTS*/

/* Scrollbar drag for every project */
.dialog-lieferheld .swiper-scrollbar-drag {
    background: #D82128;
}
.dialog-icaew .swiper-scrollbar-drag {
    background: #FC0200;
}
.dialog-supper_apps .swiper-scrollbar-drag {
    background: #C89E66;
}
.dialog-iam .swiper-scrollbar-drag {
    background: #0EAED0;
}
.dialog-ally .swiper-scrollbar-drag {
    background: #2D7EC0;
}
.dialog-mamut .swiper-scrollbar-drag {
    background: #FFFFFF;
}
.dialog-raiffeisen .swiper-scrollbar-drag {
    background: #FFFE00;
}
.dialog-mr_mrs_smith .swiper-scrollbar-drag {
    background: #740316;
}
.dialog-lemontbotanique .swiper-scrollbar-drag {
    background: #FFFFFF;
}
.dialog-bubble_nova .swiper-scrollbar-drag {
    background: #FFF564;
}
.dialog-calconvert .swiper-scrollbar-drag {
    background: #FFFFFF;
}
.dialog-timer .swiper-scrollbar-drag {
    background: #EA4487;
}
.dialog-spooky_hill .swiper-scrollbar-drag {
    background: #4471fe;
}
.dialog-gothenburg .swiper-scrollbar-drag {
    background: #0091D8;
}
.dialog-affligem .swiper-scrollbar-drag {
    background: #cc3333;
}
.dialog-zukunf_lauft .swiper-scrollbar-drag {
    background: #E4027C;
}
.dialog-brave_zone .swiper-scrollbar-drag {
    background: #E42037;
}
.dialog-msf .swiper-scrollbar-drag {
    background: #EE342A;
}

.dialog-gasag .swiper-scrollbar-drag {
    background: #FFFFFF;
}

.dialog-massup .swiper-scrollbar-drag {
    background: #dc692e;
}

.dialog-hmd .swiper-scrollbar-drag {
    background: #ffffff;
}

.dialog-siili .swiper-scrollbar-drag {
    background: #ffff00;
}

.dialog-opentalk .swiper-scrollbar-drag {
    background: #9bd247;
}

.dialog-fitogram .swiper-scrollbar-drag {
    background: #6cbaff;
}

.dialog-quieton .swiper-scrollbar-drag {
    background: #F7F6EA;
}

.swiper-container-vertical > .main-page-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    width: 10px;
    height: 100%;
}
.main-page-scrollbar .swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: #02cbc9;
    border-radius: 0;
    left: 0;
    top: 0;
}
@media only screen and (max-width: 600px) {
    .swiper-container-vertical > .main-page-scrollbar {
        width: 6px;
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 32px;
    padding-right: 0px;
    pointer-events: none;
}

@media only screen and (min-width: 768px) and (min-height: 500px) {
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 56px;
        padding-right: 44px;
    }
}

@media only screen and (min-width: 1440px) and (min-height: 700px) {
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 82px;
        padding-right: 70px;
    }
}

.swiper-pagination {
    position: absolute;
    text-align: right;
    -webkit-transition: 300ms opacity;
    -o-transition: 300ms opacity;
    transition: 300ms opacity;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .swiper-pagination {
        text-align: center;
    }
}

.swiper-container-centered .swiper-pagination {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

/* horizontal */
.droxic-bullet-class {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    margin: 0 20px 3px 20px;
    pointer-events: all;
    outline: none;
}

.droxic-active-bullet-class {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid black;
    margin: 0 20px;
    pointer-events: none;
}
/* PROJECTS - SLIDER BULLETS FOR THIRD SLIDE */
.droxic-bullet-class_lieferheld,
.droxic-bullet-class_icaew,
.droxic-bullet-class_supper_apps,
.droxic-bullet-class_iam,
.droxic-bullet-class_ally,
.droxic-bullet-class_mamut,
.droxic-bullet-class_raiffeisen,
.droxic-bullet-class_mr_mrs_smith,
.droxic-bullet-class_lemontbotanique,
.droxic-bullet-class_bubble_nova,
.droxic-bullet-class_calconvert,
.droxic-bullet-class_timer,
.droxic-bullet-class_spooky_hill,
.droxic-bullet-class_gothenburg,
.droxic-bullet-class_affligem,
.droxic-bullet-class_zukunf_lauft,
.droxic-bullet-class_brave_zone,
.droxic-bullet-class_msf,
.droxic-bullet-class_gasag,
.droxic-bullet-class_massup,
.droxic-bullet-class_hmd,
.droxic-bullet-class_siili,
.droxic-bullet-class_opentalk,
.droxic-bullet-class_fitogram,
.droxic-bullet-class_quieton{
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin: 0 20px 3px 20px;
}
.droxic-active-bullet-class_lieferheld,
.droxic-active-bullet-class_icaew,
.droxic-active-bullet-class_supper_apps,
.droxic-active-bullet-class_iam,
.droxic-active-bullet-class_ally,
.droxic-active-bullet-class_mamut,
.droxic-active-bullet-class_raiffeisen,
.droxic-active-bullet-class_mr_mrs_smith,
.droxic-active-bullet-class_lemontbotanique,
.droxic-active-bullet-class_bubble_nova,
.droxic-active-bullet-class_calconvert,
.droxic-active-bullet-class_timer,
.droxic-active-bullet-class_spooky_hill,
.droxic-active-bullet-class_gothenburg,
.droxic-active-bullet-class_affligem,
.droxic-active-bullet-class_zukunf_lauft,
.droxic-active-bullet-class_brave_zone,
.droxic-active-bullet-class_msf,
.droxic-active-bullet-class_gasag,
.droxic-active-bullet-class_massup,
.droxic-active-bullet-class_hmd,
.droxic-active-bullet-class_siili,
.droxic-active-bullet-class_opentalk,
.droxic-active-bullet-class_fitogram,
.droxic-active-bullet-class_quieton {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 100%;
    margin: 0 20px;
}

.swiper-pagination .droxic-bullet-class_lieferheld {
    background: #D82128;
}
.swiper-pagination .droxic-active-bullet-class_lieferheld {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #D82128;
}

.swiper-pagination .droxic-bullet-class_icaew {
    background: #FC0200;
}
.swiper-pagination .droxic-active-bullet-class_icaew {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FC0200;
}

.swiper-pagination .droxic-bullet-class_supper_apps {
    background: #C89E66;
}
.swiper-pagination .droxic-active-bullet-class_supper_apps {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #C89E66;
}

.swiper-pagination .droxic-bullet-class_iam {
    background: #0EAED0;
}
.swiper-pagination .droxic-active-bullet-class_iam {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #0EAED0;
}

.swiper-pagination .droxic-bullet-class_ally {
    background: #2D7EC0;
}
.swiper-pagination .droxic-active-bullet-class_ally {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #2D7EC0;
}

.swiper-pagination .droxic-bullet-class_mamut {
    background: #FFFFFF;
}
.swiper-pagination .droxic-active-bullet-class_mamut {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFFFF;
}

.swiper-pagination .droxic-bullet-class_raiffeisen {
    background: #FFFE00;
}
.swiper-pagination .droxic-active-bullet-class_raiffeisen {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFE00;
}

.swiper-pagination .droxic-bullet-class_mr_mrs_smith {
    background: #740316;
}
.swiper-pagination .droxic-active-bullet-class_mr_mrs_smith {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #740316;
}

.swiper-pagination .droxic-bullet-class_lemontbotanique {
    background: #FFFFFF;
}
.swiper-pagination .droxic-active-bullet-class_lemontbotanique {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFFFF;
}

.swiper-pagination .droxic-bullet-class_bubble_nova {
    background: #FFF564;
}
.swiper-pagination .droxic-active-bullet-class_bubble_nova {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFF564;
}

.swiper-pagination .droxic-bullet-class_calconvert {
    background: #FFFFFF;
}
.swiper-pagination .droxic-active-bullet-class_calconvert {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFFFF;
}

.swiper-pagination .droxic-bullet-class_timer {
    background: #EA4487;
}
.swiper-pagination .droxic-active-bullet-class_timer {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #EA4487;
}

.swiper-pagination .droxic-bullet-class_spooky_hill {
    background: #4471fe;
}
.swiper-pagination .droxic-active-bullet-class_spooky_hill {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #4471fe;
}

.swiper-pagination .droxic-bullet-class_gothenburg {
    background: #0091D8;
}
.swiper-pagination .droxic-active-bullet-class_gothenburg {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #0091D8;
}

.swiper-pagination .droxic-bullet-class_affligem {
    background: #CC0000;
}
.swiper-pagination .droxic-active-bullet-class_affligem {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #CC0000;
}

.swiper-pagination .droxic-bullet-class_zukunf_lauft {
    background: #E4027C;
}
.swiper-pagination .droxic-active-bullet-class_zukunf_lauft {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #E4027C;
}

.swiper-pagination .droxic-bullet-class_brave_zone {
    background: #CC0000;
}
.swiper-pagination .droxic-active-bullet-class_brave_zone {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #E42037;
}
.swiper-pagination .droxic-bullet-class_msf {
    background: #EE342A;
}
.swiper-pagination .droxic-active-bullet-class_msf {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #EE342A;
}

.swiper-pagination .droxic-bullet-class_gasag {
    background: #FFFFFF;
}
.swiper-pagination .droxic-active-bullet-class_gasag {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFFFF;
}

.swiper-pagination .droxic-bullet-class_massup {
    background: #dc692e;
}
.swiper-pagination .droxic-active-bullet-class_massup {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #dc692e;
}

.swiper-pagination .droxic-bullet-class_hmd {
    background: #FFFFFF;
}
.swiper-pagination .droxic-active-bullet-class_hmd {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid #FFFFFF;
}

.swiper-pagination .droxic-bullet-class_siili {
    background: #FFFF00;
}
.swiper-pagination .droxic-active-bullet-class_siili {
    background-color: rgba(255, 255, 0, 0);
    border: 3px solid #FFFF00;
}

.swiper-pagination .droxic-bullet-class_opentalk {
    background: #9bd247;
}
.swiper-pagination .droxic-active-bullet-class_opentalk {
    background-color: rgba(255, 255, 0, 0);
    border: 3px solid #9bd247;
}

.swiper-pagination .droxic-bullet-class_fitogram {
    background: #6cbaff;
}
.swiper-pagination .droxic-active-bullet-class_fitogram {
    background-color: rgba(255, 255, 0, 0);
    border: 3px solid #6cbaff;
}

.swiper-pagination .droxic-bullet-class_quieton {
    background: #F7F6EA;
}
.swiper-pagination .droxic-active-bullet-class_quieton {
    background-color: rgba(255, 255, 0, 0);
    border: 3px solid #F7F6EA;
}


@media only screen and (max-width: 600px) {
    .droxic-bullet-class,
    .droxic-bullet-class_lieferheld,
    .droxic-bullet-class_icaew,
    .droxic-bullet-class_supper_apps,
    .droxic-bullet-class_iam,
    .droxic-bullet-class_ally,
    .droxic-bullet-class_mamut,
    .droxic-bullet-class_raiffeisen,
    .droxic-bullet-class_mr_mrs_smith,
    .droxic-bullet-class_lemontbotanique,
    .droxic-bullet-class_bubble_nova,
    .droxic-bullet-class_calconvert,
    .droxic-bullet-class_timer,
    .droxic-bullet-class_spooky_hill,
    .droxic-bullet-class_gothenburg,
    .droxic-bullet-class_affligem,
    .droxic-bullet-class_zukunf_lauft,
    .droxic-bullet-class_brave_zone,
    .droxic-bullet-class_msf,
    .droxic-bullet-class_gasag,
    .droxic-bullet-class_massup,
    .droxic-bullet-class_hmd,
    .droxic-bullet-class_siili,
    .droxic-bullet-class_opentalk,
    .droxic-bullet-class_fitogram,
    .droxic-bullet-class_quieton {
        width: 6px;
        height: 6px;
        margin: 2px 20px 2px 20px;
    }
    .droxic-active-bullet-class,
    .swiper-pagination .droxic-active-bullet-class_lieferheld,
    .swiper-pagination .droxic-active-bullet-class_icaew,
    .swiper-pagination .droxic-active-bullet-class_supper_apps,
    .swiper-pagination .droxic-active-bullet-class_iam,
    .swiper-pagination .droxic-active-bullet-class_ally,
    .swiper-pagination .droxic-active-bullet-class_mamut,
    .swiper-pagination .droxic-active-bullet-class_raiffeisen,
    .swiper-pagination .droxic-active-bullet-class_mr_mrs_smith,
    .swiper-pagination .droxic-active-bullet-class_lemontbotanique,
    .swiper-pagination .droxic-active-bullet-class_bubble_nova,
    .swiper-pagination .droxic-active-bullet-class_calconvert,
    .swiper-pagination .droxic-active-bullet-class_timer,
    .swiper-pagination .droxic-active-bullet-class_spooky_hill,
    .swiper-pagination .droxic-active-bullet-class_gothenburg,
    .swiper-pagination .droxic-active-bullet-class_affligem,
    .swiper-pagination .droxic-active-bullet-class_zukunf_lauft,
    .swiper-pagination .droxic-active-bullet-class_brave_zone,
    .swiper-pagination .droxic-active-bullet-class_msf,
    .swiper-pagination .droxic-active-bullet-class_gasag,
    .swiper-pagination .droxic-active-bullet-class_massup,
    .swiper-pagination .droxic-active-bullet-class_hmd,
    .swiper-pagination .droxic-active-bullet-class_siili,
    .swiper-pagination .droxic-active-bullet-class_opentalk,
    .swiper-pagination .droxic-active-bullet-class_fitogram,
    .swiper-pagination .droxic-active-bullet-class_quieton {
        width: 10px;
        height: 10px;
        border-width: 2px;
        margin: 0 20px;
    }
}

/* vertical */
.swiper-container-vertical > .swiper-pagination-bullets {
    right: 90px;
}
@media only screen and (max-width: 1200px) {
    .swiper-container-vertical > .swiper-pagination-bullets {
        right: 60px;
    }
}
@media only screen and (max-width: 992px) {
    .swiper-container-vertical > .swiper-pagination-bullets {
        right: 40px;
    }
}
@media only screen and (max-width: 600px) {
    .swiper-container-vertical > .swiper-pagination-bullets {
        right: 10px;
    }
}
