@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';
@value --darkMenuBackgroundColor from '../../../styles/abstracts/colors.css';

.menuBackground {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;
    background-color: #443842;
    color: white;
    z-index: 999;
    pointer-events: auto;
    transition: all 0.45s cubic-bezier(0.16, 1, 0.3, 1) .1s;
}

.menuBackgroundOpen {
    left: 0;
    transition: all 0.45s cubic-bezier(0.16, 1, 0.3, 1);
}

@media --to-tablet-width {
    .menuBackground {
        width: calc(100% - 180px);
        padding: 64px 64px;
    }

    .menuBackgroundOpen {
        left: 180px;
    }
}

@media --to-small-desktop-width {
    .menuBackground {
        width: 50%;
    }

    .menuBackgroundOpen {
        left: 50%;
    }
}

@media --to-big-desktop-width {
    .menuBackground {
        padding: 90px;
    }
}
