.page-background__image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-background__team-image {
    width: 100%;
    height: 100%;
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
}

.page-background__video {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}