@value --robinsegg, --white from '../../../styles/abstracts/colors.css';
@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';

.infoButton {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: --robinsegg;
    display: inline-block;
    padding: 0 30px;
    border: 3px solid --robinsegg;
    border-radius: 6px;
    background-color: transparent;
    outline: none;
    font-size: 18px;
    line-height: 40px;
    transition: all 0.35s ease-out 0s;
    cursor: pointer;
}

.infoButton:hover {
    background-color: --robinsegg;
    color: --white;
    text-decoration: none;
}

.whiteButton {
    background-color: --robinsegg;
    border-color: --white;
    color: --white;
}

@media --to-tablet-width {
    .infoButton {
        padding: 0 30px;
        font-size: 20px;
        line-height: 46px;
    }
}

@media --to-small-desktop-width {
    .infoButton {
        padding: 0 32px;
        font-size: 22px;
        line-height: 52px;
    }
}

@media --to-big-desktop-width {
    .infoButton {
        font-size: 24px;
        line-height: 56px;
    }
}
