@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';
@value --white, --activeLanguageColor from '../../../styles/abstracts/colors.css';

.container {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    padding-left: 200px;
}

.container a,
.container a:visited,
.container a:link {
    font-size: 16px;
    line-height: 26px;
    color: --white;
    font-weight: 600;
}

.container a {
    margin-right: 24px;
}

.container a:last-child {
    margin-right: 0;
}

.container a.active {
    text-decoration: line-through;
    font-weight: 300;
    color: --activeLanguageColor;
    pointer-events: none;
}

@media --to-tablet-width {
    .container {
        justify-content: flex-start;
    }

    .container a,
    .container a:visited,
    .container a:link {
        font-size: 18px;
        line-height: 24px;
    }
}

@media --to-big-desktop-width {
    .container a,
    .container a:visited,
    .container a:link {
        font-size: 20px;
    }
}
