@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';
@value --white, --gray from '../../../styles/abstracts/colors.css';

.container {
    opacity: 0;
    padding-left: 40px;
}

.droxicText {
    color: --gray;
    font-size: 18px;
    line-height: 30px;
}

.socialLinksContainer {
    display: flex;
}

.socialLinksContainer > a > svg,
.clutchIcon {
    width: 30px;
    height: 30px;
}

.socialLinksContainer > a {
    margin-right: 20px;
    padding: 10px 0;
}

.socialLinksContainer > a:last-child {
    margin-right: 0;
}

.dataProtectionLink {
    display: inline-block;
    font-size: 18px;
    line-height: 30px;
}

.dataProtectionLink:link,
.dataProtectionLink:visited,
.dataProtectionLink:hover,
.dataProtectionLink:active {
    color: --white;
}

.bottomContainer {
    margin-bottom: -6px;
}

.socialLinksContainer > .youtubeLink {
    padding-left: 6px;
}

.clutchContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;
}

.clutchInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.clutchScore {
    font-size: 14px;
    font-weight: 500;
    color: --white;
}

.clutchReviews {
    font-size: 14px;
    font-weight: 300;
    color: --white;
}


@media --to-tablet-width {
    .droxicText,
    .dataProtectionLink {
        font-size: 18px;
        line-height: 30px;
    }

    .socialLinksContainer {
        margin-bottom: 4px;
    }

    .socialLinksContainer > a > svg,
    .clutchIcon {
        width: 38px;
        height: 38px;
    }

    .socialLinksContainer > a {
        margin-right: 30px;
    }

    .clutchContainer {
        margin-bottom: 10px;
    }
}

@media --to-big-desktop-width {
    .droxicText,
    .dataProtectionLink {
        font-size: 20px;
        line-height: 36px;
    }

    .socialLinksContainer {
        margin-bottom: 8px;
    }

    .socialLinksContainer > a > svg,
    .clutchIcon {
        width: 42px;
        height: 42px;
    }

    .socialLinksContainer > a {
        margin-right: 32px;
    }

    .bottomContainer {
        margin-bottom: -8px;
    }

    .socialLinksContainer > .youtubeLink {
        padding-left: 8px;
    }

    .clutchContainer {
        margin-bottom: 12px;
    }
}
