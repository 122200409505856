@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';
@value --contactsCity, --contactsSelectedCity from '../../../styles/abstracts/colors.css';

.mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.leftContainer {
    width: 100%;
    height: 100%;
}

.rightContainer {
    position: relative;
    height: 100%;
}

.rightContainer ul {
    position: absolute;
    list-style-type: none;
    padding: 0;
    margin: 0;
    left: 40px;
}

.rightContainer li {
    font-weight: 500;
    color: --contactsCity;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.rightContainer li::before {
    content: "";
    margin: 0;
}

.rightContainer li.selectedCity {
    color: --contactsSelectedCity;
    cursor: auto;
    pointer-events: none;
}

@media --to-tablet-width {
    .leftContainer {
        width: calc(100% - 250px);
    }

    .rightContainer {
        width: 250px;
    }

    .rightContainer ul {
        bottom: 46px;
    }

    .rightContainer li {
        font-size: 26px;
    }
}

@media --to-small-desktop-width {
    .leftContainer {
        width: calc(100% - 400px);
    }

    .rightContainer {
        width: 400px;
    }

    .rightContainer ul {
        bottom: 67px;
    }

    .rightContainer li {
        font-size: 32px;
    }
}

@media --to-big-desktop-width {
    .leftContainer {
        width: calc(100% - 512px);
    }

    .rightContainer {
        width: 512px;
    }

    .rightContainer ul {
        bottom: 102px;
    }

    .rightContainer li {
        font-size: 36px;
    }
}