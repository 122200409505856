.drx-dialog {
  position: absolute;
  top: 100vh;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: 0.5s;
}

.drx-dialog.drx-dialog-open {
  top: 0;
}

.drx-dialog .drx-dialog-fixed-logo {
  display: block;
  position: fixed;
  visibility: hidden;
  opacity: 0;
}

.drx-dialog.drx-dialog-open .drx-dialog-fixed-logo {
  display: inline-block;
  visibility: visible;
  opacity: 1;
  position: fixed;
  top: 105px;
  left: 105px;
  transform: scale(.75);
  z-index: 1001;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
  transform-origin: top left;
}

@media only screen and (max-width: 1200px) {
  .drx-dialog.drx-dialog-open .drx-dialog-fixed-logo {
    top: 65px;
    left: 65px;
    transform: scale(.55);
  }
}
@media only screen and (max-width: 992px) {
  .drx-dialog.drx-dialog-open .drx-dialog-fixed-logo {
    top: 50px;
    left: 50px;
    transform: scale(.45);
  }
}

@media only screen and (max-width: 600px) {
  .drx-dialog.drx-dialog-open .drx-dialog-fixed-logo {
    top: 25px;
    left: 25px;
    transform: scale(.4);
  }
}


.slide-item-two-cols {
  display: flex;
}

.slide-item-two-cols .slide-item {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .slide-item-two-cols {
    flex-direction: column;
  }

  .slide-item-two-cols .slide-item {
    width: 100%;
    height: 50%;
  }
}

.slide-item {
  height: 100%;
}

.slide-item > section {
  height: 100%;
}

.slide-item-work {
  height: 100%;
  background-color: #000000;
}

.slide-item-work .work-item {
  width: 100%;
  height: 100%;
  position: relative;
  /* transition: all 0.4s; */
  overflow: hidden;
  cursor: pointer;
}

  .slide-item-work .work-item .info-slide-background,
  .slide-item-work .work-item .work-item-content {
    transition: transform 0.4s;
  }

  .slide-item-work .work-item:hover .info-slide-background {
    transform: scale(1.05, 1.05);
  }

  .slide-item-work .work-item:hover .work-item-content {
    transform: translate(-50%, -50%) scale(1.3, 1.3);
  }

  .slide-item-work .work-item .info-slide-background {
    transform: scale(1.2);
  }

.work-item .work-item-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1, 1);
  text-align: center;
}

.work-item .work-item-content img {
  transform: scale(.75);
}
@media only screen and (max-width: 1200px) {
    .work-item .work-item-content img {
        transform: scale(.55);
    }
}
@media only screen and (max-width: 992px) {
    .work-item .work-item-content img {
        transform: scale(.45);
    }
}
@media only screen and (max-width: 600px) {
    .work-item .work-item-content img {
        transform: scale(.55);
    }
}

.slide-item-flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.slide-item-count-1 > section {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .slide-item-count-2 {
    flex-direction: column;
  }
}

.slide-item-count-2 > section {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .slide-item-count-2 > section {
    width: 100%;
    height: 50%;
  }

  .slide-item-count-2 > .small-slide-item {
    height: 40%;
  }

  .slide-item-count-2 > .big-slide-item {
    height: 60%;
  }
}

.slide-item-work.slide-item-count-3 > section {
  width: 33.3333333%;
  height: 100%;
}

.slide-item-work.slide-item-count-4 > section {
  height: 50%;
}

.slide-item-count-4 > section {
  width: 50%;
  height: 50%;
}

.slide-item-count-5 > section,
.slide-item-count-6 > section {
  width: 33.3333333%;
  height: 50%;
}

.slide-item-count-7 > section,
.slide-item-count-8 > section {
  width: 25%;
  height: 50%;
}

.slide-item-count-9 > section {
  width: 33.3333333%;
  height: 33.3333333%;
}
