@value --to-mobile-width, --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../styles/abstracts/breakpoints.css';

.mainContainer {
    position: absolute;
    bottom: 32px;
    width: 100%;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.mainContainerWithPagination {
    bottom: 84px;
}

.mainContainerAlignLeft {
    justify-content: space-between;
}



.container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.container > *:last-child {
    margin-bottom: 0;
}

.containerAlignLeft {
    text-align: left;
    align-items: flex-start;
}

.containerWithLinkedIn {
    flex-basis: calc(100% - 40px);
}

.description {
    font-size: 22px;
}

.iconContainer {
    margin-bottom: 12px;
}

.icon {
    height: 28px;
    margin-right: 12px;
}

.longDescription {
    margin: 0;
}

.buttonContainer {
    margin-top: 24px;
}

.linkedIn {
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    background-image: url("../../images/linkedin.svg");
}

@media --to-mobile-width {
    .mainContainerAlignTop {
        bottom: 0;
        top: 32px;
        align-items: flex-start;
    }

    .smallerTitleSize {
        font-size: 20px;
        margin-bottom: 4px;
    }

    .smallerSubtitleSize {
        font-size: 20px;
        margin-bottom: 4px;
    }
}

@media --to-tablet-width {
    .mainContainer {
        bottom: 64px;
        padding: 0 64px;
        justify-content: space-between;
    }

    .container {
        text-align: left;
        align-items: flex-start;
    }

    .description {
        font-size: 24px;
    }

    .icon {
        height: 34px;
        margin-right: 14px;
    }
}

@media --to-small-desktop-width {
    .containerWithMouseAnimation {
        flex-basis: calc(100% - 108px);
    }

    .description {
        font-size: 30px;
    }

    .icon {
        height: 40px;
        margin-right: 26px;
    }
}

@media --to-big-desktop-width {
    .mainContainer {
        bottom: 90px;
        padding: 0 90px;
    }

    .containerWithMouseAnimation {
        flex-basis: calc(100% - 134px);
    }

    .description {
        font-size: 36px;
    }

    .icon {
        height: 44px;
        margin-right: 36px;
    }
}
