@value --mineshaft, --black, --turquoise from '../abstracts/colors.css';
@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../abstracts/breakpoints.css';

h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 10px;
}

h2 {
    font-size: 22px;
    font-weight: 300;
    line-height: 1.2;
    color: --mineshaft;
    margin: 0;
}

h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    color: --turquoise;
    margin: 0;
}

p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    color: --black;
    text-align: left;
    margin: 0;
}

ul {
    padding: 0 0 0 15px;
    margin: 0;
}

li {
    list-style-type: none;
    font-weight: 300;
    font-size: 14px;
    color: --black;
    line-height: 1.2;
}

li::before {
    content: "-";
    margin: 0 10px 0 -15px;
}

a:link,
a:visited,
a:hover,
a:active {
    color: --turquoise;
    text-decoration: none;
}

@media --to-tablet-width {
    h1 {
        font-size: 45px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 25px;
    }

    p {
        font-size: 20px;
    }

    li {
        font-size: 18px;
    }
}

@media --to-small-desktop-width {
    h1 {
        font-size: 54px;
    }

    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 30px;
    }

    p {
        font-size: 24px;
    }

    li {
        font-size: 20px;
    }
}

@media --to-big-desktop-width {
    h1 {
        font-size: 64px;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        font-size: 36px;
    }

    p {
        font-size: 28px;
    }

    li {
        font-size: 24px;
    }
}
