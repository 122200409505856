@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../../styles/abstracts/breakpoints.css';
@value --black from '../../../styles/abstracts/colors.css';

.mainContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.backgroundContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

address {
    position: absolute;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    padding: 0 20px;
    width: 100%;
    text-align: center;
    font-style: normal;
}

address a {
    display: block;
}

.city {
    font-size: 30px;
    font-weight: 600;
    color: --black;
}

.addressLine, address a {
    font-size: 22px;
}

.addressLine:last-child {
    margin-bottom: 16px;
}

.tel:visited, .tel:hover, .tel:active, .tel:link {
    color: --black;
}

.email {
    font-weight: 500;
}

@media --to-tablet-width {
    address {
        transform: none;
        text-align: left;
        padding: 0 46px;
        top: unset;
        bottom: 46px;
    }

    .city {
        font-size: 46px;
    }

    .addressLine, address a {
        font-size: 26px;
    }

    .addressLine:last-child {
        margin-bottom: 20px;
    }
}

@media --to-small-desktop-width {
    address {
        padding: 0 67px;
        bottom: 67px;
    }

    .city {
        font-size: 54px;
    }

    .addressLine, address a {
        font-size: 32px;
    }

    .addressLine:last-child {
        margin-bottom: 24px;
    }
}

@media --to-big-desktop-width {
    address {
        padding: 0 102px;
        bottom: 102px;
    }

    .city {
        font-size: 64px;
    }

    .addressLine, address a {
        font-size: 36px;
    }

    .addressLine:last-child {
        margin-bottom: 30px;
    }
}
