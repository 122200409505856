@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../styles/abstracts/breakpoints.css';

.mainButton {
    position: absolute;
    z-index: 2000;
    width: 66px;
    height: 58px;
    top: 15px;
    right: 12px;
    pointer-events: all;
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: transparent;
}

.mainButton:focus {
    outline: none;
}

.mainButtonSvg {
    vertical-align: middle;
    width: 30px;
    height: 25px;
}

.mainButtonSvgHiddenShape {
    opacity: 0;
}

@media --to-tablet-width {
    .mainButton {
        width: 92px;
        height: 82px;
        top: 36px;
        right: 30px;
    }

    .mainButtonSvg {
        width: 30px;
        height: 24px;
    }
}

@media --to-big-desktop-width {
    .mainButton {
        top: 60px;
        right: 56px;
    }
}
