@value --to-tablet-width from '../../styles/abstracts/breakpoints.css';
@value --whiteMenuBackgroundClose, --whiteMenuBackgroundOpen from '../../styles/abstracts/colors.css';

.mainContainer {
    position: absolute;
    z-index: 999;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: --whiteMenuBackgroundClose;
    transition: background-color 0.25s 0.3s;
}

.mainContainerOpen {
    background-color: transparent;
    pointer-events: auto;
    transition: background-color 0.25s 0.25s;
}

@media --to-tablet-width {
    .mainContainerOpen {
        background-color: --whiteMenuBackgroundOpen;
    }
}
