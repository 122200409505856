@value --to-small-desktop-width, --to-big-desktop-width from '../../styles/abstracts/breakpoints.css';

.container {
    box-sizing: content-box;
    position: absolute;
    width: 30px;
    height: 44px;
    bottom: 112px;
    right: 112px;
    visibility: hidden;
    z-index: 1;
}

.mouseShape {
    position: absolute;
    width: 30px;
    height: 44px;
    border: 2px solid black;
    border-radius: 20px;
}

.wheel {
    position: absolute;
    top: 6px;
    left: 12px;
    width: 6px;
}

.wheelHiddenShapes {
    visibility: hidden;
}

@media --to-small-desktop-width {
    .container {
        bottom: 64px;
        right: 64px;
        visibility: visible;
    }
}

@media --to-big-desktop-width {
    .container {
        bottom: 92px;
        right: 92px;
    }
}
