@value --to-tablet-width, --to-small-desktop-width, --to-big-desktop-width from '../../styles/abstracts/breakpoints.css';
@value --robinsegg, --white from '../../styles/abstracts/colors.css';

.container {
    position: fixed;
    z-index: 10;
    width: 100%;
    right: 0;
    bottom: 0;
    padding: 32px;
    background-color: --robinsegg;
    text-align: center;
}

.container p {
    font-size: 20px;
    color: --white;
    margin-bottom: 30px;
}

.container a,
.container a:link,
.container a:visited,
.container a:hover,
.container a:focus {
    font-size: 20px;
    color: --white;
    text-decoration: underline;
}

@media --to-tablet-width {
    .container {
        width: 440px;
        padding: 64px;
        text-align: right;
    }

    .container p {
        margin-bottom: 60px;
    }
}
