html, body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: -1px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

* {
    box-sizing: border-box;
}
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.drx-default {
    height: 100%;
}

.drx-animated-page {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
}

.main-container {
    height: 100%;
}
