.work-slide-item-v1 {}

.work-slide-item-v1 .work-slide-details,
.work-slide-item-v2 .work-slide-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.work-slide-item-v1 .work-slide-details > div,
.work-slide-item-v2 .work-slide-details > div {
  position: relative;
  width: 70%;
  height: 100%;
  margin: 0 auto;
  padding: 30px 0 0;
}

.work-slide-item-v1 .work-slide-details .work-slide-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work-slide-item-v1 .work-slide-details h1 {
  margin: 0;
  font-size: 40px;
}

.work-slide-item-v1 .work-slide-details h2 {
  margin: 0;
  font-size: 26px;
}

.work-slide-item-v1 .work-slide-details .work-slide-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 70%;
}

.work-slide-details h1,
.work-slide-details h2,
.work-slide-details .info-slide-description {
  color: #FFFFFF;
}

.work-slide-item-v2 .work-slide-details .work-slide-header {
  position: absolute;
  bottom: 10%;
  width: 100%;
}

.work-slide-item-v2 .work-slide-details .work-slide-image-wrapper {
  text-align: center;
}

.work-slide-item-v2 .work-slide-details .work-slide-image {
  max-width: 100%;
  /*max-height: 300px;*/
}

.work-slide-item-v2 .work-slide-details h2 {
  margin: 0;
  font-size: 38px;
  font-weight: 400;
}

.work-slide-item-v3 {}
.work-slide-item-v3 .slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
}
@media only screen and (max-width: 600px) {
  .work-slide-item-v3 .slide-item {
    padding: 32% 8%;
  }
}

.work-slide-item-v3.lieferheld .slide-item {
  background-color: #2D333C;
}
.work-slide-item-v3.icaew .slide-item {
  background-color: #4B4B4B;
}
.work-slide-item-v3.supper_apps .slide-item {
  background-color: #2A2F34;
}
.work-slide-item-v3.iam .slide-item {
  background-color: #E7E8EA;
}
.work-slide-item-v3.ally .slide-item {
  background-color: #E1F2FF;
}
.work-slide-item-v3.mamut .slide-item {
  background-color: #CC0000;
}
.work-slide-item-v3.raiffeisen .slide-item {
  background-color: #343A3C;
}
.work-slide-item-v3.mr_mrs_smith .slide-item {
  background-color: #2D4254;
}
.work-slide-item-v3.lemontbotanique .slide-item {
  background-color: #750D39;
}
.work-slide-item-v3.bubble_nova .slide-item {
  background-color: #392961;
}
.work-slide-item-v3.calconvert .slide-item {
  background-color: #F76B00;
}
.work-slide-item-v3.timer .slide-item {
  background-color: #FFFFFF;
}
.work-slide-item-v3.spooky_hill .slide-item {
  background-color: #FBE020;
}
.work-slide-item-v3.gothenburg .slide-item {
  background-color: #95C23B;
}
.work-slide-item-v3.affligem .slide-item {
  background-color: #E3B770;
}
.work-slide-item-v3.zukunf_lauft .slide-item {
  background-color: #FFFFFF;
}
.work-slide-item-v3.brave_zone .slide-item {
  background-color: #C6C6C6;
}
.work-slide-item-v3.msf .slide-item {
  background-color: #FFFFFF;
}
.work-slide-item-v3.gasag .slide-item {
  background-color: #004e76;
}
.work-slide-item-v3.hmd .slide-item {
  background-color: #37a2f1;
}
.work-slide-item-v3.siili .slide-item {
  background-color: #777777;
}
.work-slide-item-v3.opentalk .slide-item {
  background-color: #20434f;
}

.work-slide-item-v3.fitogram .slide-item {
  background-color: #FFFFFF;
}

.work-slide-item-v3.quieton .slide-item {
  background-color: #8c8f96;
}