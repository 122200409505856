/*TODO: This file must be deleted!*/

.info-slide {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;
  /*transition: all 1s;*/
}

@media only screen and (max-width: 600px) {
  .swiper-wrapper .swiper-slide > section.info-slide-reversed-order:first-child {
    order: 2;
  }

  .swiper-wrapper .swiper-slide > section.info-slide-reversed-order:last-child {
    order: 1;
  }

  .swiper-wrapper .swiper-slide > section.info-slide-reversed-order:last-child .info-slide-details {
    bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
  }

  .slide-careers-list .swiper-wrapper .swiper-slide > section.info-slide-reversed-order:first-child .info-slide-details {
    top: 0;
    bottom: initial;
    text-align: left;
    padding: 0 30px;
  }

  .swiper-wrapper .swiper-slide > section.info-slide-reversed-order:first-child .info-slide-details {
    bottom: 0;
    left: 0;
  }
}

.info-slide.slide-work h1,
.info-slide.slide-work h2,
.info-slide.slide-work .info-slide-description,
.info-slide.slide-work--second-page h1,
.info-slide.slide-work--second-page h2,
.info-slide.slide-work--second-page .info-slide-description {
  color: #FFFFFF;
}

.info-slide-bgk-scale .info-slide-background {
  transition: all 0.4s;
}

.info-slide-bgk-scale:hover .info-slide-background {
  transform: scale(1.1);
}

/* Team dialog
Backgrounds are positioned a bit out of center
*/
.info-slide-team-bgk-scale .info-slide-background,
.info-slide-reversed-order .info-slide-background{
  background-position: center 15%;
  transition: all 0.4s;
}

.info-slide-team-bgk-scale:hover .info-slide-background,
.info-slide-reversed-order:hover .info-slide-background {
  transform: scale(1.05);
}


/*  Details container  */
.info-slide-details {
  position: absolute;
  left: 102px;
  bottom: 102px;
  width: calc(100% - 204px);
  z-index: 1;
}
.info-slide.about-main-slide .info-slide-details,
.info-slide.slide-careers .info-slide-details,
.info-slide.slide-work .info-slide-details,
.info-slide.slide-expertise .info-slide-details,
.info-slide.first-reference .info-slide-details {
  width: calc(100% - 356px);
}

@media only screen and (max-width: 1200px) {
  .info-slide-details {
    left: 67px;
    bottom: 67px;
    width: calc(100% - 134px);
  }
  .info-slide.about-main-slide .info-slide-details,
  .info-slide.slide-careers .info-slide-details,
  .info-slide.slide-work .info-slide-details,
  .info-slide.slide-expertise .info-slide-details,
  .info-slide.first-reference .info-slide-details {
    width: calc(100% - 250px);
  }
}

@media only screen and (max-width: 992px) {
  .info-slide-details {
    left: 46px;
    bottom: 46px;
    width: calc(100% - 92px);
  }
  .info-slide.about-main-slide .info-slide-details,
  .info-slide.slide-careers .info-slide-details,
  .info-slide.slide-work .info-slide-details,
  .info-slide.slide-expertise .info-slide-details,
  .info-slide.first-reference .info-slide-details {
    width: calc(100% - 188px);
  }
}

@media only screen and (max-width: 600px) {
  .info-slide-details {
    text-align: center;
    left: 0;
    bottom: 94px;
    width: 100%;
    padding: 10px;
  }

  /* We have left aligned content for the main slide in About */
  .info-slide.about-main-slide .info-slide-details {
    text-align: left;
    left: 26px;
    bottom: 22px;
    width: calc(100% - 52px);
  }
  .info-slide.about-main-slide .info-slide-description {
    font-size: 22px;
  }

  .info-slide.slide-about .info-slide-details,
  .info-slide.slide-work .info-slide-details,
  .info-slide.slide-work--second-page .info-slide-details,
  .info-slide.slide-expertise .info-slide-details,
  .info-slide.slide-expertise--next-page .info-slide-details,
  .info-slide.slide-careers .info-slide-details {
    left: 26px;
    bottom: 22px;
    width: calc(100% - 52px);
  }

  .info-slide.slide-work .info-slide-details,
  .info-slide.slide-work--second-page .info-slide-details,
  .info-slide.slide-expertise .info-slide-details,
  .info-slide.slide-expertise--next-page .info-slide-details {
    text-align: left;
  }

  .info-slide.slide-team .info-slide-details {
    text-align: left;
    left: 14px;
    bottom: 14px;
    width: calc(100% - 28px);
  }
}

/*  BUTTON  */
.info-slide-button-wrapper {
  margin: 35px 0 0;
}

@media only screen and (max-width: 1200px) {
  .info-slide-button-wrapper {
    margin: 30px 0 0;
  }
}
@media only screen and (max-width: 992px) {
  .info-slide-button-wrapper {
    margin: 26px 0 0;
  }
}
@media only screen and (max-width: 600px) {
  .info-slide-button-wrapper {
    margin: 22px 0 0;
  }
}

/*  DROXIC logo in About */
.info-slide-image {
  display: block;
  width: 720px;
  max-width: 40%;
  margin-bottom: 54px;
}
@media only screen and (max-width: 1200px) {
  .info-slide-image {
    width: 542px;
    margin-bottom: 42px;
  }
}
@media only screen and (max-width: 992px) {
  .info-slide-image {
    width: 366px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .info-slide-image {
    width: 60%;
    max-width: 60%;
    display: inline-block;
    margin-bottom: 18px;
  }
}

.info-slide-background {
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}
.video-cover {
  min-width: 100%;
  min-height: 100%;
  z-index: 0;

  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.info-slide-linkedin {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  display: inline-block;
  background-image: url("../../../images/linkedin.svg");
}

@media only screen and (max-width: 600px) {
  .info-slide-linkedin {
    right: 6px;
    bottom: 6px;
    transform: scale(.75);
  }
}

/*.info-slide-icon {*/
/*  transform: scale(1);*/
/*  margin-right: 36px;*/
/*}*/
/*@media only screen and (max-width: 1200px) {*/
/*  .info-slide-icon {*/
/*    transform: scale(.9);*/
/*    margin-right: 26px;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 992px) {*/
/*  .info-slide-icon {*/
/*    transform: scale(.8);*/
/*    margin-right: 14px;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 600px) {*/
/*  .info-slide-icon {*/
/*    transform: scale(.6);*/
/*    margin-right: 2px;*/
/*  }*/
/*}*/
