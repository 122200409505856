.page-content-common {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 90px 15% 30px;
  overflow: auto;
}

  .page-content-common h1.page-content-common-heading {
    font-weight: bold;
    line-height: normal;
    font-size: 28px;
    color: #01CBC9;
  }

  .page-content-common .page-content-common-wrapper {
    padding: 20px 0;
    color: #212121;
  }

    .page-content-common section {
      margin-bottom: 40px;
    }

      .page-content-common h2 {
        padding-bottom: 30px;
        font-size: 24px;
      }

      .page-content-common p {
        padding-bottom: 15px;
        font-size: 18px;
      }

      .page-content-common p a {
        font-weight: bold;
        color: #000000;
      }

.page-content-common h2 a {
  font-weight: bold;
  color: #000000;
}

.page-content-common li {
  font-size: 18px;
  list-style-type: initial;
  margin: 0 0 0 24px;
}

.page-content-common li::before {
  display: none;
}

  @media only screen and (max-width: 600px) {
    .page-content-common {
      padding: 70px 0 0;
    }

    .page-content-common h1.page-content-common-heading {
      padding: 0 8%;
      font-size: 18px;
    }

    .page-content-common .page-content-common-wrapper {
      padding: 0 8%;
    }

    .page-content-common section {
      margin-bottom: 30px;
    }

    .page-content-common h2 {
      padding-bottom: 15px;
      font-size: 18px;
    }

    .page-content-common p {
      padding-bottom: 7px;
      font-size: 14px;
    }

    .page-content-common li {
      font-size: 14px;
    }
  }

