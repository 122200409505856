.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.listItem::before {
    content: "";
    margin: 0;
    padding: 0;
}
